import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: ["token", "user", "store"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
    store: null,
    productTypes: [],
  },

  getters: {
    authenticated: ({ token, user }) => !!token && !!user,
    token: ({ token }) => token,
    user: ({ user }) => user,
    store: ({ store }) => store,
    productTypes: ({ productTypes }) => productTypes,
    apiURL: () => process.env.VUE_APP_API_URL,
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_STORE(state, payload) {
      state.user = payload;
    },

    SET_PRODUCT_TYPES(state, payload) {
      state.productTypes = payload;
    },
  },

  actions: {
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
      api.defaults.headers.common["Authorization"] = payload;
    },

    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", null);
      dispatch("setUser", null);
    },

    setStore({ commit }, payload) {
      commit("SET_STORE", payload);
    },

    setProductTypes({ commit }, payload) {
      commit("SET_PRODUCT_TYPES", payload);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
