import store from "../store";

export default async (to, from, next) => {
  //settings
  document.title = to.name + " - Alio | Criadores";
  if (to.name != from.name) window.scrollTo(0, 0);

  const tk = to.query.tk || false;
  if (tk) store.dispatch("setToken", tk);

  // handle auth state
  const requiresAuth = to.meta.requiresAuth || false;
  const preventAuth = to.meta.preventAuth || false;
  const authenticated = store.getters.authenticated;

  // if the user has not authenticated and tries access private page
  if (requiresAuth && !authenticated) return next("/auth");

  // if the user has authenticated and tries access prevent authenticated page
  if (preventAuth && authenticated) return next("/");

  // else... next
  next();
};
