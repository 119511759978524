<template>
  <v-card class="pa-2 pa-md-4 rounded-lg" width="100%" outlined>
    <div class="d-flex align-center">
      <v-icon color="background" size="2rem" left>
        {{ info.icon }}
      </v-icon>

      <div class="font-weight-medium text-md-body-1 text-caption">
        {{ info.title }}
      </div>
    </div>

    <div class="d-flex align-center">
      <v-icon class="mr-2" color="green"> mdi-trending-up </v-icon>

      <div class="text-h6 text-md-h5 font-weight-bold secondary--text mr-2">
        {{ info.value }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
