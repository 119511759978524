<template>
  <div class="d-flex flex-column justify-center fill-height">
    <section v-if="$vuetify.breakpoint.mdAndUp">
      <div class="text-h4 font-weight-regular mb-2">
        Crie seu primeiro produto!
      </div>

      <div class="text-body-1 mb-6">
        Venda qualquer produto digital, Serviço Agendamentos e mais.
      </div>
    </section>

    <section class="d-flex align-center">
      <v-btn
        class="text-none rounded-lg mr-4"
        color="primary"
        :width="$vuetify.breakpoint.mdAndUp ? '20rem' : '100%'"
        depressed
        @click="submit()"
      >
        Crie seu produto
      </v-btn>
    </section>
  </div>
</template>

<script>
export default {
  name: "CreateProduct",

  methods: {
    submit() {
      this.$emit("submit", "createProduct");
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
