<template>
  <v-app v-if="loaded" id="app">
    <v-navigation-drawer
      v-if="_authenticated && $vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : 'neutral'"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <v-card
          :class="$vuetify.breakpoint.lgAndUp ? 'ma-2 rounded-lg' : 'rounded-0'"
          class="elevation-0"
          color="neutral"
          height="100%"
        >
          <div class="d-flex fill-height">
            <div
              class="secondary left-marker"
              :class="
                $vuetify.breakpoint.lgAndUp ? 'rounded-l-lg' : 'rounded-0'
              "
            />

            <div class="d-flex flex-column fill-width">
              <!-- logo -->
              <div class="d-flex justify-center mt-6 mb-8">
                <v-img
                  :src="require('@/assets/logo.svg')"
                  contain
                  max-width="8rem"
                />
              </div>

              <div class="d-flex justify-center mb-6">
                <v-chip color="secondary" class="mt-n4" label small>
                  {{ user?.subscriber ? "Pro" : "Free" }}
                </v-chip>
              </div>

              <!-- routes list -->
              <v-list
                class="py-0"
                color="transparent"
                width="100%"
                height="100%"
                dense
                nav
              >
                <router-link
                  v-for="(item, i) in _routes"
                  v-slot="{ href, navigate, isActive }"
                  :to="item.path"
                  :key="i"
                  custom
                >
                  <v-tooltip :disabled="!item.tooltip" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        v-bind="attrs"
                        v-on="on"
                        class="rounded-lg"
                        :href="href"
                        :class="`${!isActive || 'background'} ${
                          !_allowed ? 'disabled' : ''
                        }`"
                        @click="navigate"
                      >
                        <v-list-item-icon class="mr-3">
                          <v-img
                            :class="{
                              'disabled-icon': item.disabled || item.isComing,
                            }"
                            :src="require(`@/assets/icons/${item.icon}.svg`)"
                            max-width="1.25rem"
                            contain
                          />
                        </v-list-item-icon>

                        <v-list-item-title class="d-flex">
                          <span
                            :class="
                              item.disabled || item.isComing
                                ? 'text2--text'
                                : 'text1--text'
                            "
                          >
                            {{ item.label }}
                          </span>

                          <v-spacer />

                          <v-chip
                            v-if="item.isComing"
                            class="absolute-tag px-2"
                            color="secondary"
                            absolute
                            x-small
                            label
                          >
                            <span
                              class="text-caption font-weight-bold white--text"
                            >
                              Em breve
                            </span>
                          </v-chip>
                        </v-list-item-title>
                      </v-list-item>
                    </template>

                    <span>{{ item.tooltip }}</span>
                  </v-tooltip>
                </router-link>
              </v-list>

              <!-- append -->
              <v-list class="pt-0" color="transparent" width="100%" dense nav>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  to="/settings"
                  custom
                >
                  <v-list-item
                    class="rounded-lg"
                    :href="href"
                    :class="`${!isActive || 'background'} ${
                      !_allowed ? 'disabled' : ''
                    }`"
                    :disabled="!_allowed"
                    @click="navigate"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-img
                        :src="require('@/assets/icons/settings-sliders.svg')"
                        max-width="1.25rem"
                        contain
                      />
                    </v-list-item-icon>

                    <v-list-item-title> Configurações </v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-list-item class="rounded-lg" @click="signOut()">
                  <v-list-item-icon class="mr-3">
                    <v-img
                      :src="require('@/assets/icons/exit.svg')"
                      max-width="1.25rem"
                      contain
                    />
                  </v-list-item-icon>

                  <v-list-item-title> Sair </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </v-card>
      </div>
    </v-navigation-drawer>

    <v-main class="background" :class="!_authenticated || 'pb-16'">
      <v-app-bar v-if="_authenticated" class="elevation-0" color="transparent">
        <v-btn
          v-if="$vuetify.breakpoint.mdOnly"
          class="ml-1"
          icon
          @click="drawer = !drawer"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-img
          v-if="$vuetify.breakpoint.smAndDown"
          :src="require('@/assets/logo.svg')"
          contain
          max-width="4rem"
        />

        <v-spacer />

        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-center store-url mx-2"
        >
          <span class="text-body-2 text-md-body-1">
            {{ _storeURL }}
          </span>
        </div>

        <v-btn
          class="text-none mr-2"
          color="primary"
          small
          outlined
          @click="copyInvite()"
        >
          <v-icon small>mdi-content-copy</v-icon>

          <span class="ml-2">
            {{ $vuetify.breakpoint.smAndUp ? "Copiar" : "Alio.bio" }}
          </span>
        </v-btn>

        <v-btn
          class="text-none"
          color="secondary"
          depressed
          small
          @click="switchToBranads()"
        >
          <v-icon small>mdi-sync</v-icon>

          Marcas
        </v-btn>

        <section v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center">
          <v-divider class="mx-4" vertical />

          <v-avatar class="mr-2" color="surface" size="2.5rem">
            <v-img
              v-if="user?.image"
              :src="$store.getters.apiURL + '/' + user?.image"
            />

            <v-icon v-else color="surface lighten-1">
              mdi-account-outline
            </v-icon>
          </v-avatar>

          <span class="text-subtitle-1 mr-1"> Olá, {{ user?.name }} </span>

          <v-tooltip v-if="user?.maluFlag || user?.carolFlag" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mt-n2"
                color="primary"
                small
              >
                mdi-check-decagram
              </v-icon>
            </template>

            <span>Verificado(a)</span>
          </v-tooltip>
        </section>
      </v-app-bar>

      <!-- signature banner -->
      <v-banner
        v-if="_authenticated && showBanner"
        class="mx-4 my-2 rounded-lg white"
        outlined
        two-line
      >
        <v-avatar slot="icon" color="secondary" size="48">
          <v-icon dark> mdi-information-symbol </v-icon>
        </v-avatar>

        <div class="text-subtitle-1">
          💡 Lembrete: A sua assinatura venceu. Você está utilizando os recursos
          da conta ALIO Pro. Verifique a sua assinatura para continuar
          desfrutando de recursos Pro e alavancando seus resultados!
        </div>

        <template v-slot:actions>
          <div class="ma-4">
            <v-btn
              class="text-none rounded-lg"
              color="primary"
              to="/financial/signature"
              depressed
            >
              Verificar assinatura
            </v-btn>
          </div>
        </template>
      </v-banner>

      <router-view />

      <v-btn
        v-if="_authenticated"
        href="https://api.whatsapp.com/send?phone=5531972258692"
        target="_blank"
        color="green"
        :class="{
          'desktop-fab': $vuetify.breakpoint.mdAndUp,
          'mobile-fab': $vuetify.breakpoint.smAndDown,
        }"
        :small="$vuetify.breakpoint.smAndDown"
        absolute
        bottom
        fixed
        right
        dark
        fab
      >
        <v-icon :large="$vuetify.breakpoint.mdAndUp">mdi-whatsapp</v-icon>
      </v-btn>

      <!-- mobile nav -->
      <v-app-bar
        v-if="_authenticated && $vuetify.breakpoint.smAndDown"
        class="mx-3 mb-2 rounded-lg"
        color="neutral"
        height="auto"
        bottom
        app
      >
        <div class="d-flex flex-wrap fill-width justify-center">
          <router-link
            v-for="(item, i) in _routes.slice(
              0,
              moreRoutes ? _routes.length : 3
            )"
            v-slot="{ href, navigate, isActive }"
            class="ma-1"
            :to="item.path"
            :key="i"
            custom
          >
            <v-tooltip :disabled="!item.tooltip" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-sheet
                  v-bind="attrs"
                  v-on="on"
                  v-ripple
                  class="d-flex flex-column fill-height align-center justify-center rounded-lg pointer"
                  min-width="3.5rem"
                  height="3rem"
                  :color="isActive ? 'background' : 'transparent'"
                  :href="href"
                  :class="
                    item.disabled || item.isComing || !_allowed
                      ? 'disabled'
                      : ''
                  "
                  flat
                  @click="navigate"
                >
                  <v-img
                    :class="{
                      'disabled-icon': item.disabled || item.isComing,
                    }"
                    :src="require(`@/assets/icons/${item.icon}.svg`)"
                    max-width="1.125rem"
                    contain
                  />

                  <span
                    class="text-mobile text-center"
                    :class="
                      item.disabled || item.isComing
                        ? 'text2--text'
                        : 'text1--text'
                    "
                  >
                    {{ item.label }}
                  </span>
                </v-sheet>
              </template>

              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </router-link>

          <router-link
            v-if="moreRoutes"
            v-slot="{ href, navigate, isActive }"
            to="/settings"
            custom
          >
            <v-sheet
              v-ripple
              class="d-flex flex-column fill-height align-center justify-center rounded-lg pointer"
              min-width="3.5rem"
              height="3rem"
              :class="!_allowed ? 'disabled' : ''"
              :color="isActive ? 'background' : 'transparent'"
              :href="href"
              flat
              @click="navigate"
            >
              <v-img
                :src="require('@/assets/icons/settings-sliders.svg')"
                max-width="1.125rem"
                contain
              />

              <span class="text1--text text-mobile text-center">
                Configurações
              </span>
            </v-sheet>
          </router-link>

          <v-sheet
            v-if="moreRoutes"
            v-ripple
            class="d-flex flex-column fill-height align-center justify-center rounded-lg pointer"
            min-width="3.5rem"
            height="3rem"
            flat
            @click="signOut()"
          >
            <v-img
              :src="require('@/assets/icons/exit.svg')"
              max-width="1.125rem"
              contain
            />

            <span class="text1--text text-mobile text-center"> Sair </span>
          </v-sheet>

          <v-sheet
            v-ripple
            class="d-flex flex-column fill-height align-center justify-center rounded-lg pointer"
            min-width="3.5rem"
            height="3rem"
            flat
            @click="moreRoutes = !moreRoutes"
          >
            <v-icon color="primary" class="mb-1">
              {{ moreRoutes ? "mdi-dots-vertical" : "mdi-dots-horizontal" }}
            </v-icon>

            <span class="text1--text text-mobile text-center">
              {{ moreRoutes ? "Menos" : "Mais" }}
            </span>
          </v-sheet>
        </div>
      </v-app-bar>
    </v-main>

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { request } from "./services";
import AlertBar from "./components/alertBar/AlertBar";
const moment = require("moment");
moment.locale("pt-BR");

export default {
  name: "App",

  data() {
    return {
      loaded: false,
      drawer: true,
      showBanner: false,
      moreRoutes: false,
    };
  },

  components: {
    AlertBar,
  },

  created() {
    this.handleSession();
  },

  mounted() {
    this.milestoneScript();
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
    this.$root.$on("get-user", this.getUser);
  },

  beforeDestroy() {
    this.$root.$off("alert", this.handleAlert);
    this.$root.$off("get-user", this.getUser);
  },

  computed: {
    ...mapState(["user"]),

    _authenticated() {
      return this.$store.getters.authenticated;
    },

    _allowed() {
      if (!this.user?.image || !this.user?.biography) return false;
      return true;
    },

    _storeURL() {
      return (process.env.VUE_APP_STORE_URL + "/" + this.user?.username)
        .replace("https://", "")
        .replace("http://", "");
    },

    _routes() {
      return [
        {
          icon: "house-chimney",
          label: "Home",
          path: "/home",
        },
        {
          icon: "apps",
          label: "Minha loja",
          path: "/store",
        },
        /* {
          icon: "filter",
          label: "Funis de Vendas",
          path: "/sales",
        }, */
        {
          icon: "wallet",
          label: "Financeiro",
          path: "/financial",
        },
        /* {
          icon: "following",
          label: "Indicações",
          path: "/indications",
        }, */
        {
          icon: "toolbox",
          label: "Campanhas",
          path: "/campaigns",
        },
        /*  {
          icon: "chart-pie-alt",
          label: "Relatórios",
          path: "/reports",
          isComing: true,
        },
        {
          icon: "square-plus",
          label: "Integrações",
          path: "/integrations",
          isComing: true,
        },
        {
          icon: "template",
          label: "Templates",
          path: "/templates",
        }, */
        /* {
          icon: "star",
          label: "Avaliações",
          path: "/assessment",
        }, */
      ];
    },
  },

  methods: {
    ...mapActions(["setToken", "setUser", "setSignOut"]),

    // checks if the token is still valid for a session
    async handleSession() {
      const token = this.$store.getters.token;

      if (token) {
        try {
          const payload = {
            method: "checkUserToken",
          };

          await request(payload);
          this.getUser();
        } catch {
          this.signOut();
        }
      }

      this.loaded = true;
    },

    // get user and set storage
    async getUser(auth = false) {
      try {
        const payload = {
          method: "getUserData",
        };

        const { data } = await request(payload);
        this.handleUser(data, auth);
      } catch (err) {
        console.log(err.data.message);
      }
    },

    async handleUser(data, auth) {
      if (data.userData.aceitouTermos === "1") {
        await this.setUser({
          id: data.userData.id,
          name: data.userData.nome,
          username: data.userData.username,
          biography: data.userData.biografia,
          image: data.userData.userImage,
          document: data.userData.cpfCnpj,
          email: data.userData.email,
          phone: data.userData.telefone,
          acceptTerms: data.userData.aceitouTermos === "1",
          sendBankKey: data.sendBankKey,
          sendDocs: data.sendDocs,
          plan: data.userData.plano,
          maluFlag: data.userData.alunoMalu === "1",
          carolFlag: data.userData.alunaCarol === "1",
          ...(data.userData.documents && {
            documents: {
              document:
                this.$store.getters.apiURL +
                "/" +
                data.userData.documents.docImage,
              selfie:
                this.$store.getters.apiURL +
                "/" +
                data.userData.documents.selfie,
              status: data.userData.documents.status,
              id: data.userData.documents.id,
              motive: data.userData.documents.motivoRecuso,
            },
          }),
          ...(data.userData.bankAccount && {
            bankAccount: {
              id: data.userData.bankAccount.id,
              bank: data.userData.bankAccount.banco,
              agency: data.userData.bankAccount.agencia,
              account: data.userData.bankAccount.conta,
              digit: data.userData.bankAccount.digito,
              date: moment(data.userData.bankAccount.dataEnvio).format(
                "DD/MM/YYYY hh:mm"
              ),
              status: data.userData.bankAccount.status,
              pixKey: {
                type: data.userData.bankAccount.tipoChave,
                key: data.userData.bankAccount.chavePix,
              },
            },
          }),
          emailConfigs: {
            scheduleNotifications:
              data.userData.emailConfigs.novaReserva === "1",
            serviceRequests:
              data.userData.emailConfigs.pedidosAtendimento === "1",
            purchaseConfirmation:
              data.userData.emailConfigs.confirmacaoCompra === "1",
            membershipCancellation:
              data.userData.emailConfigs.cancelamentoAssociacao === "1",
            recurringPayments:
              data.userData.emailConfigs.pagamentoRecorrentes === "1",
          },
          activeIndication: data.userData.ativaInvite === "1",
          analytics: data.userData.Analytics,
          abmexMembers: {
            username: data.userData.abmexMembersLink,
          },
          ...(data.userData.creatorsData &&
            Object.keys(data.userData.creatorsData).length > 0 && {
              creatorData: {
                niche: data.userData.creatorsData.ninchoID,
                birthDate: data.userData.creatorsData.dataNascimento,
                genre: data.userData.creatorsData.genero,
                platforms: data.userData.creatorsData.plataformas.split(","),
                visible: data.userData.creatorsData.mostrarParaMarcas === "1",
                zipCode: data.userData.creatorsData.endereco.cep,
                uf: data.userData.creatorsData.endereco.estado,
                city: data.userData.creatorsData.endereco.cidade,
                neighborhood: data.userData.creatorsData.endereco.bairro,
                street: data.userData.creatorsData.endereco.rua,
                complement: data.userData.creatorsData.endereco.complemento,
                number: data.userData.creatorsData.endereco.numero,
                country: data.userData.creatorsData.endereco.pais,
              },
            }),
          subscriber:
            data.userData.assinatura.valida && !data.userData.assinatura.free,
        });

        //        this.initializeMilestoneFlow();

        if (!data.userData.userImage || !data.userData.biografia)
          return this.$router.push({ path: "/set-up-store" }).catch(() => {
            /* ignore */
          });

        this.showBanner =
          !data.userData.assinatura.valida && !data.userData.assinatura.free;

        if (auth) {
          this.initializeMilestoneFlow();
          this.$router.push({ path: "/home" });
          return;
        }

        return;
      }

      this.$router.push({ path: `/auth/sign-up?continue=terms` }).catch(() => {
        /* ignore */
      });
    },

    milestoneScript() {
      const script = document.createElement("script");

      script.src =
        "https://milestone-uploaded-flows-media.s3.amazonaws.com/milestone_script.js";
      script.async = true;

      script.onload = () => {
        console.log("milestone script onload");
        this.initializeMilestoneFlow();
      };

      document.body.appendChild(script);
    },

    initializeMilestoneFlow() {
      const user = this.$store.getters.user;

      if (user) {
        const token = process.env.VUE_APP_MILESTONE_API_TOKEN;

        window.milestoneFlowClient.init(token, {
          userId: user.id,
          name: user.name,
          email: user.email,
        });

        console.log("milestoneFlowClient init");
      }
    },

    async switchToBranads() {
      try {
        const payload = {
          method: "changeToBrands",
        };

        const { data } = await request(payload);

        const url = `${process.env.VUE_APP_CLIENT_BRANDS_URL}/auth?tk=${data.authToken}`;
        window.location.href = url;
      } catch (error) {
        console.log(error);
      }
    },

    copyInvite() {
      const url = process.env.VUE_APP_STORE_URL + "/" + this.user?.username;
      navigator.clipboard.writeText(url);
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },

    async signOut() {
      await this.setSignOut();
      this.$router.push({ path: "/auth" }).catch(() => {
        /* ignore */
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-divider--vertical {
  align-self: center;
  min-height: 2.25rem !important;
  height: 2.25rem !important;
}

.left-marker {
  width: 0.5rem;
  height: 100%;
}

.text-mobile {
  font-size: 10px !important;
}

.store-url {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desktop-fab {
  margin-bottom: 2.5rem;
}

.mobile-fab {
  margin-bottom: 6.5rem;
}
</style>
