<template>
  <apexchart
    type="line"
    :height="_screenHeight"
    :options="_chartOptions"
    :series="_series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },

    series: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    apexchart: VueApexCharts,
  },

  computed: {
    _chartOptions() {
      return {
        chart: {
          type: "line",
          id: "vuechart",
          width: "100%",
        },
        colors: ["#C4C8FB"],
        xaxis: {
          categories: this.categories,
        },
        markers: {
          size: 10,
          colors: "#533EF9",
          strokeColors: "#fff",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
      };
    },

    _series() {
      return [
        {
          name: "Total",
          data: this.series,
        },
      ];
    },

    _screenHeight() {
      return window.screen.height - 555;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
