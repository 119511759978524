<template>
  <div class="d-flex flex-column relative">
    <div v-if="confirmed" class="float-icon">
      <v-icon color="success" size="1.5rem"> mdi-check </v-icon>
    </div>

    <div class="d-flex align-center mb-3">
      <div
        class="custom-avatar mr-2"
        :class="active ? 'custom-avatar-active' : 'custom-avatar-inactive'"
      >
        <span
          class="text-h6 font-weight-bold"
          :class="active ? 'white--text' : 'primary--text'"
        >
          {{ data.step }}
        </span>
      </div>

      <span class="text-body-1 font-wight-bold">
        {{ data.title }}
      </span>
    </div>

    <span class="text-caption">
      {{ data.description }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        step: 0,
        title: "",
        description: "",
      }),
    },

    active: {
      type: Boolean,
      default: false,
    },

    confirmed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
