<template>
  <v-container fluid>
    <router-view />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "@/services";

export default {
  beforeMount() {
    this.getProducts();
  },

  methods: {
    ...mapActions(["setProductTypes"]),

    async getProducts() {
      const payload = {
        method: "listProdutosTipo",
      };

      const { data } = await request(payload);

      this.setProductTypes(
        data.tiposProduto.map((el) => ({
          id: el.id,
          name: el.nome,
        }))
      );
    },
  },
};
</script>

<style></style>
