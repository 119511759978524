<template>
  <v-container fluid>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Configurações
    </div>

    <TabNavigation :tabs="tabs" type="path" keyValue="/settings" />

    <v-divider class="surface mb-4" />

    <router-view />
  </v-container>
</template>

<script>
import TabNavigation from "@/components/tabNavigation/TabNavigation";

export default {
  data() {
    return {
      tabs: [
        {
          label: "Perfil",
          value: "/profile",
        },
        {
          label: "Creator",
          value: "/creator",
        },
        {
          label: "Notificações de e-mail",
          value: "/email-notifications",
        },
      ],
    };
  },

  components: {
    TabNavigation,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
