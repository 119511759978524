<template>
  <v-container fluid>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Avaliações
    </div>

    <section>
      <TabNavigation :tabs="tabs" type="path" keyValue="/assessment" />

      <v-divider class="surface mb-4" />

      <router-view />
    </section>
  </v-container>
</template>

<script>
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";

export default {
  data() {
    return {
      tabs: [
        {
          label: "Ativas",
          value: "/active",
        },
        {
          label: "Ocultas",
          value: "/hidden",
        },
      ],
    };
  },

  components: {
    TabNavigation,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
