<template>
  <v-container fluid>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Comece configurando a sua loja
    </div>

    <!-- step header desktop -->
    <v-row v-if="$vuetify.breakpoint.mdAndUp">
      <v-col v-for="(item, i) in steps" sm="6" md="3" :key="i">
        <v-card
          class="rounded-lg pa-4"
          :class="step >= item.step || 'unactive-step'"
          height="100%"
          color="surface"
          flat
        >
          <StepItem
            :data="item"
            :active="step >= item.step"
            :confirmed="confirmed.includes(item.type)"
          />
        </v-card>
      </v-col>
    </v-row>

    <!-- view step -->
    <section class="my-4">
      <v-card
        class="rounded-lg pa-6"
        color="neutral"
        :height="$vuetify.breakpoint.mdAndUp ? '21rem' : '100%'"
        flat
      >
        <v-row class="fill-height" no-gutters>
          <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="pb-6">
            <StepItem
              :data="steps[step - 1]"
              :active="step >= steps[step - 1].step"
              :confirmed="confirmed.includes(steps[step - 1].type)"
            />
          </v-col>

          <v-col cols="12" md="4" class="pb-8 pb-md-0">
            <v-card class="rounded-lg pa-5" height="100%" outlined>
              <div class="d-flex align-center mb-4 mb-md-0">
                <v-avatar class="mr-4" size="5rem" color="surface">
                  <v-img
                    v-if="form.profilePicture"
                    :src="form.profilePicture.base64String"
                  />

                  <v-icon v-else size="48" color="white">
                    mdi-account-outline
                  </v-icon>
                </v-avatar>

                <div class="d-flex flex-column fill-width">
                  <v-card
                    class="mb-2"
                    color="background"
                    height="1rem"
                    width="50%"
                    flat
                  />

                  <v-card
                    class="mb-4"
                    color="background"
                    height="1rem"
                    width="100%"
                    flat
                  />
                </div>
              </div>

              <div
                v-if="form.socialMedia"
                class="d-flex flex-column flex-md-row align-center"
                :class="{ 'media-content': $vuetify.breakpoint.mdAndUp }"
              >
                <div
                  v-if="
                    form.socialMedia.items.filter((el) => !!el.username).length
                  "
                  class="d-flex mb-2 mb-md-0"
                >
                  <v-avatar
                    v-for="(item, i) in form.socialMedia.items.filter(
                      (el) => !!el.username
                    )"
                    size="32"
                    class="mr-2"
                    :key="i"
                  >
                    <v-img :src="`${$store.getters.apiURL}/${item.image}`" />
                  </v-avatar>
                </div>

                <div v-else class="mb-2 mr-2 mb-md-0 text-caption grey--text">
                  Nenhuma rede social<br />
                  adicionada
                </div>

                <div
                  v-if="step === 2"
                  class="text-bttn"
                  @click="$refs.socialMedia.addSocialMedia()"
                >
                  <span>Editar</span>
                </div>
              </div>

              <v-card
                v-if="step >= 3"
                class="mt-8 mt-md-12"
                color="background"
                width="100%"
                flat
              >
                <div
                  class="d-flex flex-column flex-md-row align-center justify-center pa-2"
                >
                  <v-img
                    :src="require('@/assets/bankData/wallet.svg')"
                    class="wallet-icon mb-4 mb-md-0 mr-md-4"
                    max-width="5rem"
                    contain
                  />

                  <div
                    v-if="!form.bankData"
                    class="d-flex flex-column align-center align-md-start fill-width fill-height"
                  >
                    <v-card
                      class="mb-2"
                      color="surface"
                      height="1rem"
                      width="50%"
                      flat
                    />

                    <v-card color="surface" height="1rem" width="80%" flat />
                  </div>

                  <div
                    v-else
                    class="d-flex flex-column align-center align-md-start"
                  >
                    <div class="text-body-1">
                      {{ form.bankData.agency }} {{ form.bankData.account }}
                    </div>

                    <div class="text-caption">
                      {{ form.bankData.bank.label.substring(0, 28) }}
                      {{ form.bankData.bank.label.length >= 28 ? "..." : "" }}
                    </div>

                    <div
                      v-if="step === 3"
                      class="text-bttn"
                      @click="$refs.bankData.addBankAccount()"
                    >
                      <span>Editar</span>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-card>
          </v-col>

          <v-col cols="12" md="8" class="pl-md-6">
            <ProfilePicture
              v-if="step === 1"
              ref="profilePicture"
              @update="handleData($event, 'profilePicture')"
              @submit="submitData($event, 0)"
            />

            <SocialMedia
              v-else-if="step === 2"
              ref="socialMedia"
              @update="handleData($event)"
              @submit="submitData($event, 1)"
            />

            <BankData
              v-else-if="step === 3"
              ref="bankData"
              @skip="skipStep($event, 3)"
              @update="handleData($event)"
              @submit="submitData($event, 3)"
            />

            <CreateProduct
              v-else-if="step === 4"
              ref="createProduct"
              @submit="submitData($event, 4)"
            />
          </v-col>
        </v-row>
      </v-card>
    </section>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ProfilePicture from "./partials/profilePicture/ProfilePicture";
import SocialMedia from "./partials/socialMedia/SocialMedia";
import BankData from "./partials/bankData/BankData";
import CreateProduct from "./partials/createProduct/CreateProduct";
import StepItem from "./partials/stepItem/StepItem";

export default {
  data() {
    return {
      step: 1,
      steps: [
        {
          step: 1,
          type: "profilePicture",
          title: "Adicione sua foto de perfil",
          description: "Personalize seu perfil para que fique com sua cara!",
        },
        {
          step: 2,
          type: "socialMedia",
          title: "Adicione suas redes sociais",
          description: "Conecte suas redes sociais",
        },
        {
          step: 3,
          type: "bankData",
          title: "Configure seus dados bancários",
          description: "Nós usamos a Abmex como meio de pagamento ",
        },
        {
          step: 4,
          type: "createProduct",
          title: "Crie seu primeiro produto! ",
          description:
            "Venda qualquer produto digital, Serviço Agendamentos e mais.",
        },
      ],
      form: {
        profilePicture: null,
        socialMedia: null,
        bankData: null,
        createProduct: null,
      },
      confirmed: [],
    };
  },

  components: {
    ProfilePicture,
    SocialMedia,
    BankData,
    CreateProduct,
    StepItem,
  },

  computed: {
    ...mapState(["user"]),
  },

  beforeMount() {
    if (!this.user.image) return (this.step = 1);
    if (!this.user.biography) return (this.step = 2);
    if (!this.user.sendBankKey) return (this.step = 3);

    if (this.user.image && this.user.biography && this.user.sendBankKey)
      this.$router.push({ path: "/store" }).catch(() => {
        /* ignore */
      });
  },

  methods: {
    handleData(event) {
      this.form[event.type] = event.data;
    },

    async submitData(event, index) {
      this.$root.$emit("get-user");
      this.confirmed[index] = event;

      if (index < 4) return (this.step = this.step + 1);

      this.$router.push({ path: "/product" }).catch(() => {
        /* ignore */
      });
    },

    skipStep(event, index) {
      this.confirmed[index] = event;
      this.form[event] = null;
      this.step = this.step + 1;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
