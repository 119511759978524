<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card class="pa-4">
      <div class="d-flex justify-end">
        <v-btn small color="primary" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="text-center text-body-1 font-weight-bold mb-2">Cortar</div>

      <section class="mb-4">
        <vue-cropper
          v-if="imageURL"
          ref="cropper"
          :src="imageURL"
          :aspect-ratio="1"
          class="rounded-lg crop-content"
        />
      </section>

      <div class="d-flex">
        <div class="text-bttn" @click="resetCrop()">
          <span>Resetar</span>
        </div>

        <v-spacer />

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          depressed
          large
          @click="submit()"
        >
          Cortar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  data() {
    return {
      dialog: false,
    };
  },

  components: {
    VueCropper,
  },

  props: {
    imageURL: {
      type: String,
      default: "",
    },
  },

  methods: {
    submit() {
      // file settings
      const type = "image/png";
      const fileName =
        Math.random().toString(36).substr(2, 6).toLowerCase() + ".png";

      // handle file base64 to blob
      const canvas = this.$refs.cropper.getCroppedCanvas();
      const base64String = canvas.toDataURL(type);
      console.log(base64String);

      const blob = new Blob([base64String], { type: type });
      const file = new File([blob], fileName, { type: "image/png" });

      const payload = {
        file: file,
        objectURL: URL.createObjectURL(file),
        base64String: base64String,
      };

      this.$emit("submit", payload);
      this.close();
    },

    resetCrop() {
      this.$refs.cropper.reset();
    },

    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
