<template>
  <v-container fluid>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Minha loja
    </div>

    <loader v-if="loading.store || loading.product" />

    <section v-else>
      <TabNavigation :tabs="tabs" type="path" keyValue="/store" />

      <v-divider class="surface mb-4" />

      <v-row>
        <v-col cols="12" md="7" lg="8" xl="9">
          <router-view
            :store="store"
            :products="products"
            :socialMedia="socialMedia"
            :availableThemes="availableThemes"
            @update="
              getStore();
              getProducts();
            "
          />
        </v-col>

        <v-col cols="12" md="5" lg="4" xl="3">
          <v-card class="rounded-lg" color="neutral" flat>
            <section class="pa-4">
              <div class="text-body-1 font-weight-bold mb-4">Veja a prévia</div>

              <div class="px-2">
                <v-card class="rounded-lg pa-2" outlined>
                  <!-- profile preview -->
                  <div class="d-flex">
                    <v-avatar class="mx-2" size="4rem" color="surface">
                      <v-img
                        v-if="user.image"
                        :src="$store.getters.apiURL + '/' + user.image"
                      />

                      <v-icon v-else size="2rem" color="surface lighten-1">
                        mdi-account-outline
                      </v-icon>
                    </v-avatar>

                    <div class="d-flex flex-column">
                      <div class="text-body-2 font-weight-bold mb-n1">
                        {{ store.name }}
                      </div>

                      <div class="text-caption grey--text">
                        @{{ user.username }}
                      </div>

                      <div class="text-caption grey--text mb-2">
                        {{ user.biography }}
                      </div>

                      <div class="d-flex">
                        <a
                          v-for="(item, i) in socialMedia"
                          :href="item.link + item.username"
                          target="_blank"
                          class="mr-2"
                          :key="i"
                        >
                          <v-avatar color="surface" size="1rem">
                            <v-img
                              :src="$store.getters.apiURL + '/' + item.image"
                            />
                          </v-avatar>
                        </a>
                      </div>
                    </div>

                    <v-spacer />

                    <v-img
                      v-if="_flag"
                      :src="_flag"
                      max-width="3rem"
                      height="3rem"
                      contain
                    />
                  </div>

                  <v-divider v-if="_published.length > 0" class="mx-2 my-4" />

                  <!-- products preview -->
                  <v-card
                    v-for="(item, i) in _published"
                    class="rounded-lg pa-2 mb-2"
                    :key="i"
                    outlined
                  >
                    <div class="d-flex align-center mb-2">
                      <v-avatar
                        v-if="item.productImage"
                        class="mr-2 rounded-lg"
                        height="3.5rem"
                        width="4.5rem"
                        color="background"
                      >
                        <v-img
                          :src="$store.getters.apiURL + '/' + item.productImage"
                          width="100%"
                          height="100%"
                        />
                      </v-avatar>

                      <div class="d-flex flex-column mb-3">
                        <div class="text-body-2 font-weight-bold">
                          {{ item.title }}
                        </div>

                        <div class="text-body-2">
                          {{ item.subtitle }}
                        </div>
                      </div>
                    </div>

                    <v-btn
                      v-ripple="false"
                      class="rounded-lg text-none"
                      :color="store.color"
                      depressed
                      block
                      dark
                    >
                      {{ item.btnTitle }}
                    </v-btn>
                  </v-card>
                </v-card>
              </div>
            </section>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <v-dialog v-model="dialog" max-width="400">
      <v-card class="pa-6 rounded-lg">
        <v-btn absolute top right color="primary" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <div class="text-center secondary--text text-h6 my-8">
          Agora <span class="font-weight-bold">conte para todos</span> sobre
          isso!
        </div>

        <v-img
          :src="require('@/assets/product/megafone.png')"
          class="mx-16 mb-8"
        />

        <div class="text-center text1--text mb-4">Visite a minha loja!</div>

        <v-card
          class="rounded-lg d-flex flex-column flex-md-row pa-2"
          color="foreground"
          persistent
          flat
        >
          <input
            v-model="_inviteUrl"
            class="mb-4 mb-md-0"
            readonly
            @click="copyInvite()"
          />

          <v-spacer />

          <v-btn
            class="rounded-lg text-none"
            color="primary"
            small
            depressed
            @click="copyInvite()"
          >
            <v-icon small>mdi-content-copy</v-icon>
            <span class="ml-2"> Copiar </span>
          </v-btn>
        </v-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { request } from "@/services";
import TabNavigation from "@/components/tabNavigation/TabNavigation";

export default {
  data() {
    return {
      loading: {
        store: true,
        products: true,
      },
      dialog: false,
      tabs: [
        {
          label: "Sessões",
          value: "/sessions",
        },
        {
          label: "Editar Designs",
          value: "/edit-designs",
        },
      ],
      store: {},
      products: [],
      socialMedia: [],
      availableThemes: [],
    };
  },

  components: {
    TabNavigation,
  },

  beforeMount() {
    if (this.$route.query.dialog) this.dialog = true;

    this.getStore();
    this.getProducts();
  },

  computed: {
    ...mapState(["user"]),

    _inviteUrl() {
      return process.env.VUE_APP_STORE_URL + "/" + this.user.username;
    },

    _published() {
      return this.products.filter((el) => el.published);
    },

    _flag() {
      if (this.user.maluFlag) return require("@/assets/flags/malu-flag.png");
      if (this.user.carolFlag) return require("@/assets/flags/carol-flag.png");

      return false;
    },
  },

  methods: {
    async getStore() {
      const payload = {
        method: "getMinhaLoja",
      };

      await request(payload)
        .then(({ data }) => {
          this.store = {
            id: data.minhaLoja.id,
            color: data.minhaLoja.corLoja.toUpperCase(),
            name: data.minhaLoja.nomeNaLoja,
            published: data.minhaLoja.published,
            themeID: data.minhaLoja.temaID,
          };

          this.socialMedia = data.redesSocias.map((el) => ({
            id: el.redeID,
            image: el.imgRede,
            link: el.linkRede,
            name: el.nomeRede,
            username: el.userRedeSocial,
          }));

          this.availableThemes = data.temasDisponiveis.map((el) => ({
            id: el.id,
            name: el.temaNome,
          }));
        })
        .finally(() => {
          this.loading.store = false;
        });
    },

    async getProducts() {
      const payload = {
        method: "listMyProdutos",
      };

      await request(payload)
        .then(({ data }) => {
          this.products = data.myprodutos.map((el) => ({
            id: el.id,
            createdAt: el.dataCriacao,
            productImage: el.imgProduto,
            linkID: el.linkID,
            title: el.titulo,
            subtitle: el.subtitulo,
            btnTitle: el.tituloBotao,
            type: el.tipo,
            productType: {
              id: el.tipoProdutoID,
              name: el.tipoProdutoNome,
            },
            published: el.published === "1",
            valid: el.valid === "1",
            visible: el.visivel === "1",
          }));
        })
        .finally(() => {
          this.loading.products = false;
        });
    },

    closeDialog() {
      this.dialog = false;
      this.$router.replace({ query: [] });
    },

    copyInvite() {
      navigator.clipboard.writeText(this._inviteUrl);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
