<template>
  <div class="d-flex flex-column justify-center fill-height">
    <section v-if="$vuetify.breakpoint.mdAndUp">
      <div class="text-h4 font-weight-regular mb-2">
        Adicione sua foto de perfil

        <v-icon v-if="confirmed" color="success" large>mdi-check</v-icon>
      </div>

      <div class="text-body-1 mb-6">
        Personalize seu perfil para que fique com sua cara!
      </div>
    </section>

    <section v-show="!cropedPicture">
      <FileInput
        v-model="profilePicture"
        ref="pictureInput"
        :accept="'image/jpeg,image/jpg,image/png'"
        @input="$refs.cropImage.open()"
      >
        <v-btn
          class="text-none rounded-lg"
          color="primary"
          :width="$vuetify.breakpoint.mdAndUp ? '24rem' : '100%'"
          depressed
          @click="selectFiles()"
        >
          Adicione sua foto de perfil
        </v-btn>
      </FileInput>
    </section>

    <section
      v-if="cropedPicture && !confirmed"
      class="d-flex flex-column flex-md-row align-center"
    >
      <v-btn
        class="text-none rounded-lg mr-md-4 mb-4 mb-md-0"
        color="primary"
        :width="$vuetify.breakpoint.mdAndUp ? '20rem' : '100%'"
        :loading="loading"
        depressed
        @click="submit()"
      >
        Confirmar
      </v-btn>

      <div v-if="!loading" class="text-bttn" @click="selectFiles()">
        <span>Trocar foto</span>
      </div>
    </section>

    <section
      v-else-if="confirmed"
      class="d-flex justify-center justify-md-start"
    >
      <div class="text-bttn" @click="nextStep()">
        <span>Próximo passo</span>
        <v-icon color="primary" right>mdi-arrow-right</v-icon>
      </div>
    </section>

    <CropImage
      ref="cropImage"
      :imageURL="profilePicture ? profilePicture.objectURL : ''"
      @submit="handleProfilePicture($event)"
    />
  </div>
</template>

<script>
import { request } from "@/services";
import FileInput from "@/components/fileInput/FileInput";
import CropImage from "./partials/cropImage/CropImage";

export default {
  name: "ProfilePicture",

  data() {
    return {
      loading: false,
      confirmed: false,
      profilePicture: null,
      cropedPicture: null,
    };
  },

  components: {
    FileInput,
    CropImage,
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          method: "uploadProfilePhoto",
          profileImage: this.profilePicture.file,
        };

        await request(payload).then(() => {
          this.confirmed = true;
        });
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    nextStep() {
      this.$emit("submit", "profilePicture");
    },

    selectFiles() {
      this.profilePicture = null;
      this.$refs.pictureInput.selectFiles();
    },

    handleProfilePicture(event) {
      this.cropedPicture = event;

      const payload = {
        type: "profilePicture",
        data: event,
      };

      this.$emit("update", payload);
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
