<template>
  <div
    :class="$vuetify.breakpoint.mdAndUp ? 'darken-purple' : 'lighten-purple'"
    class="d-flex fill-height"
  >
    <v-container fill-height>
      <v-row justify="center">
        <v-col v-if="!hideCard" cols="12" md="6" xl="4">
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="d-flex justify-center"
            style="height: 100vh"
          >
            <v-img
              class="rounded-lg"
              :src="require(`@/assets/auth/img-auth.png`)"
              contain
            />
          </div>
        </v-col>

        <v-col
          class="d-flex flex-column"
          cols="12"
          md="6"
          xl="4"
          align-self="center"
        >
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-8">
            <v-row class="d-flex justify-center">
              <v-col cols="6" sm="5">
                <v-img class="mb-8" :src="require(`@/assets/logo-white.svg`)" />
              </v-col>
            </v-row>

            <div class="text-h6 text-center neutral--text font-weight-regular">
              O link na bio inteligente
            </div>
          </div>

          <router-view @hide-card="hideCard = true" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Auth",

  data() {
    return {
      hideCard: false,
    };
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
