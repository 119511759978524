<template>
  <loader v-if="loading" class="my-4" />

  <v-container fluid v-else>
    <router-view :campaign="campaign" />
  </v-container>
</template>

<script>
import { request } from "@/services";

export default {
  name: "Campaign",

  data() {
    return {
      loading: true,
    };
  },

  beforeMount() {
    this.getCampaign();
  },

  methods: {
    async getCampaign() {
      try {
        this.loading = true;

        const payload = {
          method: "getCampanhaDetail",
          campanhaID: this.$route.params.id,
        };

        const { data } = await request(payload);

        this.campaign = {
          id: data.campanhaDetail.id,
          title: data.campanhaDetail.titulo,
          createdAt: data.campanhaDetail.dataCriacao,
          dateStart: data.campanhaDetail.inicioCampanha,
          dateEnd: data.campanhaDetail.fimCampanha,
          paymentType: data.campanhaDetail.formatPgmt,
          location: data.campanhaDetail.local,
          platforms: data.campanhaDetail.plataformas.split(","),
          niche: data.campanhaDetail.ninchoID,
          ...(Object.keys(data.campanhaDetail.produto).length && {
            product: {
              id: data.campanhaDetail.produto.id,
              description: data.campanhaDetail.produto.descricao,
              sendType: data.campanhaDetail.produto.envio,
              url: data.campanhaDetail.produto.url,
              image:
                process.env.VUE_APP_API_URL +
                "/" +
                data.campanhaDetail.produto.imgProduto,
            },
          }),
          ...(Object.keys(data.campanhaDetail.briefing).length && {
            briefing: {
              id: data.campanhaDetail.briefing.id,
              typeContent: data.campanhaDetail.briefing.tipoConteudo.split(","),
              midiaFormat: data.campanhaDetail.briefing.formatoMidia.split(","),
              typeEdition: data.campanhaDetail.briefing.tipoEdicao.split(","),
              hasMusic: data.campanhaDetail.briefing.musica,
              videoType: data.campanhaDetail.briefing.tipoDeVideoID,
              startedAt: data.campanhaDetail.briefing.inicioDuracao,
              endAt: data.campanhaDetail.briefing.fimDuracao,
              mustDo: data.campanhaDetail.briefing.devemFazer,
              notDo: data.campanhaDetail.briefing.naoDevemFazer,
              videoReference: data.campanhaDetail.briefing.referencias,
              additionalInfo: data.campanhaDetail.briefing.infoAdicionais,
              qntVideos: data.campanhaDetail.briefing.qntVideos,
            },
          }),
          type: data.campanhaDetail.tipo,
          budgetFor: data.campanhaDetail.valorInicio,
          budgetUntil: data.campanhaDetail.valorFim,
          status: data.campanhaDetail.status,
          applied: data.campanhaDetail.temAplicacao,
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
