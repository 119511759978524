<template>
  <div class="d-flex flex-column justify-center fill-height">
    <section v-if="$vuetify.breakpoint.mdAndUp">
      <div class="text-h4 font-weight-regular mb-2">
        Adicione suas redes sociais

        <v-icon v-if="confirmed" color="success" large>mdi-check</v-icon>
      </div>

      <div class="text-body-1 mb-6">Conecte suas redes sociais</div>
    </section>

    <section v-if="!socialMedia">
      <v-btn
        class="text-none rounded-lg"
        color="primary"
        :width="$vuetify.breakpoint.mdAndUp ? '24rem' : '100%'"
        depressed
        @click="addSocialMedia()"
      >
        Adicione suas redes sociais
      </v-btn>
    </section>

    <section v-else-if="socialMedia && !confirmed" class="d-flex align-center">
      <v-btn
        class="text-none rounded-lg mr-4"
        color="primary"
        :width="$vuetify.breakpoint.mdAndUp ? '20rem' : '100%'"
        :loading="loading"
        depressed
        @click="submit()"
      >
        Confirmar
      </v-btn>
    </section>

    <section
      v-else-if="confirmed"
      class="d-flex justify-center justify-md-start"
    >
      <div class="text-bttn" @click="nextStep()">
        <span>Próximo passo</span>
        <v-icon color="primary" right>mdi-arrow-right</v-icon>
      </div>
    </section>

    <AddSocialMedia ref="addSocialMedia" @submit="handleSocialMedia($event)" />
  </div>
</template>

<script>
import { request } from "@/services";
import AddSocialMedia from "./partials/addSocialMedia/AddSocialMedia";

export default {
  name: "SocialMedia",

  data() {
    return {
      loading: false,
      confirmed: false,
      socialMedia: null,
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    AddSocialMedia,
  },

  methods: {
    async submit() {
      this.loading = true;

      await this.sendBiography();

      if (
        this.socialMedia &&
        this.socialMedia.items.filter((el) => !!el.username)
      )
        await this.sendSocialMedia();

      this.loading = false;
    },

    async sendSocialMedia() {
      try {
        this.loading = true;

        for (const el of this.socialMedia.items.filter((el) => !!el.username)) {
          const payload = {
            method: "addRedeSocial",
            redeID: el.id,
            userRedeSocial: el.isNumber
              ? el.username.replaceAll(/[^0-9]/gim, "")
              : el.username,
          };

          await request(payload).then(() => {
            this.confirmed = true;
          });
        }
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async sendBiography() {
      try {
        const payload = {
          method: "uploadBio",
          biografia: this.socialMedia.biography,
        };

        await request(payload).then(() => {
          this.confirmed = true;
        });
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      }
    },

    nextStep() {
      this.$emit("submit", "socialMedia");
    },

    addSocialMedia() {
      this.$refs.addSocialMedia.open();
    },

    handleSocialMedia(event) {
      this.socialMedia = event;

      const payload = {
        type: "socialMedia",
        data: event,
      };

      this.$emit("update", payload);
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
