import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Home from "../views/home/Home.vue";
import Auth from "../views/auth/Auth.vue";
import SetUpStore from "../views/setUpStore/SetUpStore.vue";
import Product from "../views/product/Product.vue";
import Store from "../views/store/Store.vue";
import Financial from "../views/financial/Financial.vue";
/* import Indications from "../views/indications/Indications.vue"; */
import Campaigns from "../views/campaigns/Campaigns.vue";
import Campaign from "../views/campaign/Campaign.vue";
import Assessment from "../views/assessment/Assessment.vue";
import Settings from "../views/settings/Settings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "",
        name: "Auth: Sign in",
        meta: { preventAuth: true },
        component: () => import("../views/auth/children/signIn/SignIn.vue"),
      },
      {
        path: "sign-up/:invite_id?",
        name: "Auth: Sign up",
        meta: { preventAuth: true },
        component: () => import("../views/auth/children/signUp/SignUp.vue"),
      },
      {
        path: "forgot-password",
        name: "Auth: Forgot Password",
        meta: { preventAuth: true },
        component: () =>
          import("../views/auth/children/forgotPassword/ForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "Home",
    meta: { requiresAuth: true },
    component: Home,
  },
  {
    path: "/set-up-store",
    name: "Set up store",
    meta: { requiresAuth: true },
    component: SetUpStore,
  },
  {
    path: "/product",
    redirect: "/product/type",
    component: Product,
    children: [
      {
        path: "type",
        name: "Product: Select Type",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/product/children/selectType/SelectType.vue"),
      },
      {
        path: "create",
        name: "Product: Create Product",
        meta: { requiresAuth: true },
        component: () => import("../views/product/children/create/Create.vue"),
      },
      {
        path: "edit/:id",
        name: "Product: Edit Product",
        meta: { requiresAuth: true },
        component: () => import("../views/product/children/edit/Edit.vue"),
      },
    ],
  },
  {
    path: "/store",
    redirect: "/store/sessions",
    meta: { requiresAuth: true },
    component: Store,
    children: [
      {
        path: "sessions",
        name: "Store: Sessions",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/store/children/sessions/Sessions.vue"),
      },
      {
        path: "edit-designs",
        name: "Store: Edit Designs",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/store/children/editDesigns/EditDesigns.vue"),
      },
    ],
  },
  {
    path: "/financial",
    redirect: "/financial/sales",
    meta: { requiresAuth: true },
    component: Financial,
    children: [
      {
        path: "sales",
        name: "Financial: Sales",
        meta: { requiresAuth: true },
        component: () => import("../views/financial/children/sales/Sales.vue"),
      },
      /* {
        path: "signature",
        name: "Financial: Signature",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/financial/children/signature/Signature.vue"),
      }, */
      {
        path: "withdraw",
        name: "Financial: Withdraw",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/financial/children/withdraw/Withdraw.vue"),
      },
      {
        path: "creator-withdraw",
        name: "Financial: Creator Withdraw",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../views/financial/children/creatorWithdraw/CreatorWithdraw.vue"
          ),
      },
    ],
  },
  /* {
    path: "/indications",
    name: "Indications",
    meta: { requiresAuth: true },
    component: Indications,
  }, */
  {
    path: "/campaigns",
    redirect: "/campaigns/new-campaigns",
    meta: { requiresAuth: true },
    component: Campaigns,
    children: [
      {
        path: "new-campaigns",
        name: "Settings: New campaigns",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/campaigns/children/newCampaigns/NewCampaigns.vue"),
      },
      {
        path: "my-applications",
        name: "Settings: My applications",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../views/campaigns/children/myApplications/MyApplications.vue"
          ),
      },
    ],
  },
  {
    path: "/campaign/:id",
    name: "Campaign",
    meta: { requiresAuth: true },
    component: Campaign,
    children: [
      {
        path: "details",
        name: "CampaignDetails",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../views/campaign/children/campaignDetails/CampaignDetails.vue"
          ),
      },
      {
        path: "apply",
        name: "ApplyCampaign",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/campaign/children/applyCampaign/ApplyCampaign.vue"),
      },
      {
        path: "chat/:applicationID",
        name: "ChatBrand",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/campaign/children/chatBrand/ChatBrand.vue"),
      },
    ],
  },
  {
    path: "/assessment",
    redirect: "/assessment/active",
    meta: { requiredAuth: true },
    component: Assessment,
    children: [
      {
        path: "active",
        name: "Assessment: Active",
        meta: { requiredAuth: true },
        component: () =>
          import("../views/assessment/children/active/Active.vue"),
      },
      {
        path: "hidden",
        name: "Assessment: Hidden",
        meta: { requiredAuth: true },
        component: () =>
          import("../views/assessment/children/hidden/Hidden.vue"),
      },
    ],
  },
  {
    path: "/settings",
    redirect: "/settings/profile",
    meta: { requiresAuth: true },
    component: Settings,
    children: [
      {
        path: "profile",
        name: "Settings: Profile",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/settings/children/profile/Profile.vue"),
      },
      {
        path: "creator",
        name: "Settings: Creator",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/settings/children/creator/Creator.vue"),
      },
      {
        path: "email-notifications",
        name: "Settings: Email Notifications",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../views/settings/children/emailNotifications/EmailNotifications.vue"
          ),
      },
    ],
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
