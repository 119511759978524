<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card class="pa-4">
      <div class="d-flex justify-end">
        <v-btn small color="primary" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-form v-model="valid" class="mb-6" ref="form">
        <v-text-field
          v-model="form.biography"
          label="Bio"
          placeholder="Digite uma pequena descrição de você"
          class="mb-6"
          maxlength="80"
          :rules="_rules"
          counter
        />

        <div v-if="form.items.length">
          <div v-for="(item, i) in form.items" class="mb-6" :key="i">
            <div class="d-flex align-center mb-2">
              <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '40' : '20'">
                <v-img :src="`${$store.getters.apiURL}/${item.image}`" />
              </v-avatar>

              <v-divider class="mx-2 surface" vertical />

              <v-icon v-if="!item.isEmail" class="mr-2">
                {{ item.isNumber ? "mdi-phone" : "mdi-at" }}
              </v-icon>

              <div class="d-flex flex-column">
                <input
                  v-if="item.isNumber"
                  v-model="item.username"
                  v-mask="'+55 (##) #####-####'"
                  placeholder="Seu número"
                  class="input"
                />

                <input
                  v-else
                  v-model="item.username"
                  :placeholder="
                    item.isEmail
                      ? 'Seu endereço de e-mail'
                      : 'Seu nome de usuário'
                  "
                  class="input"
                  :class="{ 'mb-n1': item.name.toLowerCase() === 'instagram' }"
                />

                <div
                  v-if="item.name.toLowerCase() === 'instagram'"
                  class="text-caption mb-n4"
                  :class="{
                    'red--text': requiredInstagram,
                    'grey--text': !requiredInstagram,
                  }"
                >
                  Campo obrigatório*
                </div>
              </div>
            </div>

            <v-divider class="surface" />
          </div>

          <div
            v-if="options.length > 3 && options.length !== form.items.length"
            class="text-bttn mb-6"
            @click="moreSocialMedia()"
          >
            <span>Mais redes sociais</span>
          </div>
        </div>

        <div
          v-else
          class="d-flex align-center justify-center text-center grey--text text-body-2"
        >
          Nenhuma rede social diponível
        </div>
      </v-form>

      <v-btn
        class="text-none rounded-lg"
        color="primary"
        depressed
        large
        block
        @click="submit()"
      >
        Salvar
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      requiredInstagram: false,
      form: {
        biography: "",
        items: [],
      },
      options: [],
    };
  },

  directives: {
    mask,
  },

  beforeMount() {
    this.getSocialMedias();
  },

  watch: {
    ["form.items"]: {
      handler(val) {
        const instagram = val.find(
          (el) => el.name.toLowerCase() === "instagram"
        );

        if (instagram && instagram.username) this.requiredInstagram = false;
      },
      deep: true,
    },
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async getSocialMedias() {
      const payload = {
        method: "listRedesSocias",
      };

      await request(payload).then(({ data }) => {
        this.options = data.redesSocias.map((el) => ({
          id: el.id,
          image: el.imgRede,
          link: el.linkRede,
          name: el.nomeRede,
          isNumber: el.nomeRede.toLowerCase() === "whatsapp",
          isEmail:
            el.nomeRede.toLowerCase() === "e-mail" || el.nomeRede === "email",
          username: "",
        }));

        this.form.items = this.options.slice(0, 3);
      });
    },

    submit() {
      if (!this.$refs.form.validate()) return;

      if (
        !this.form.items.find(
          (el) => el.name.toLowerCase() === "instagram" && el.username
        )
      ) {
        this.requiredInstagram = true;
        return;
      }

      this.$emit("submit", this.form);
      this.close();
    },

    moreSocialMedia() {
      this.form.items.push(...this.options.slice(3));
    },

    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
