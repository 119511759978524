<template>
  <v-container fluid>
    <div
      class="text-h6 text-sm-h5 font-weight-bold text-center text-sm-left mb-6"
    >
      Olá, {{ user?.name }}!
    </div>

    <loader v-if="loading" />

    <section v-else>
      <div
        class="d-flex flex-column flex-md-row align-end align-md-center mb-2 mb-md-0"
      >
        <TabNavigation
          :tabs="tabs"
          type="value"
          @change="handleFilter($event)"
        />

        <v-spacer />

        <div class="report-content">
          <div class="text-bttn">
            <span>Ver Relatórios</span>
          </div>
        </div>
      </div>

      <v-divider class="mb-4" />

      <v-card class="rounded-lg pa-4" flat>
        <v-row>
          <v-col
            v-for="(item, i) in infos"
            :key="i"
            class="d-flex justify-space-around"
            cols="12'"
            sm="6"
          >
            <AnalyticalCard :info="item" />
          </v-col>
        </v-row>

        <loader v-if="loaderChart" class="mt-12 mb-10" />

        <SalesChart v-else :series="chartSeries" :categories="categories" />
      </v-card>
    </section>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { request } from "@/services";
import { currencyMask } from "@/utils";
import TabNavigation from "@/components/tabNavigation/TabNavigation";
import AnalyticalCard from "./partials/analyticalCard/AnalyticalCard";
import SalesChart from "./partials/salesChart/SalesChart";
const moment = require("moment");
moment.locale("pt-BR");

export default {
  name: "Home",

  data() {
    return {
      loading: true,
      loaderChart: true,
      filter: 7,
      tabs: [
        {
          label: "Últimos 7 dias",
          value: 7,
        },
        {
          label: "Últimos 14 dias",
          value: 14,
        },
        {
          label: "Últimos mês",
          value: 30,
        },
      ],
      infos: [
        {
          icon: "mdi-eye",
          title: "Visualização de Site",
          value: "0",
          percent: "0",
        },
        {
          icon: "mdi-cash",
          title: "Total de Vendas",
          value: "0",
          percent: "0",
        },
        /* {
          icon: "mdi-account-multiple",
          title: "Clientes",
          value: "0",
          percent: "0",
        }, */
      ],
      categories: [],
      chartSeries: [],
    };
  },

  components: {
    TabNavigation,
    AnalyticalCard,
    SalesChart,
  },

  beforeMount() {
    this.getDashboard();
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async getDashboard() {
      const payload = {
        method: "getDashboard",
        dataInicial: moment()
          .subtract(this.filter + 1, "days")
          .format("YYYY-MM-DD"),
        dataFinal: moment().format("YYYY-MM-DD"),
      };

      await request(payload)
        .then(({ data }) => {
          this.infos[0].value = data.totalDeViews;
          this.infos[1].value = currencyMask(data.totalDeVendas);
          this.chartSeries = this.handleChartSeries(data.graficoVenda);
        })
        .finally(() => {
          this.loading = false;
          this.loaderChart = false;
        });
    },

    handleFilter(event) {
      this.loaderChart = true;

      this.filter = event.value;
      const dates = [];

      for (let i = 0; i < this.filter; i++) {
        const date = moment().subtract(i, "days").format("DD/MM/YYYY");
        dates.push(date);
      }

      this.categories = dates.reverse();
      this.getDashboard();
    },

    handleChartSeries(data) {
      const dates = Array.from({ length: this.filter }, (_, i) => {
        const date = new Date();

        date.setDate(date.getDate() - i);
        return moment(date).format("DD/MM/YYYY");
      });

      return dates
        .map((el) => {
          const salle = data.find((sl) => sl.dataVenda === el);
          return salle ? currencyMask(salle.totalVendido) : "0,00";
        })
        .reverse();
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
