<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card class="pa-4">
      <div class="d-flex justify-end">
        <v-btn small color="primary" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="text-h6 mb-6">Dados Bancários</div>

      <v-form v-model="valid" ref="form">
        <v-autocomplete
          v-model="form.bank"
          label="Banco*"
          class="mb-4"
          item-text="label"
          return-object
          overflow
          :menu-props="{ 'max-width': 368, overflowY: true }"
          :items="_banks"
          :rules="_rules"
        />

        <v-text-field
          v-model="form.agency"
          v-mask="'######'"
          label="Código da Agência*"
          class="mb-4"
          :rules="_rules"
        />

        <v-text-field
          v-model="form.account"
          v-mask="'##########'"
          label="Conta corrente*"
          class="mb-4"
          :rules="_rules"
        />

        <v-text-field
          v-model="form.digit"
          v-mask="'#'"
          label="Dígito*"
          class="mb-4"
          :rules="_rules"
        />

        <v-text-field
          v-model="form.document"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          label="CPF/CNPJ"
          class="mb-6"
          disabled
          :rules="_documentRules"
        />

        <v-btn
          class="text-none rounded-lg"
          color="primary"
          depressed
          large
          block
          @click="submit()"
        >
          Salvar
        </v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { mask } from "vue-the-mask";
const banks = require("@/assets/bankData/banks.json");

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      form: {
        bank: null,
        agency: "",
        account: "",
        digit: "",
        document: "",
      },
    };
  },

  directives: {
    mask,
  },

  beforeMount() {
    this.form.document = this.user.document;
  },

  computed: {
    ...mapState(["user"]),

    _banks() {
      return banks;
    },

    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    _documentRules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;

      this.$emit("submit", this.form);
      this.close();
    },

    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
