<template>
  <div class="d-flex flex-column justify-center fill-height">
    <section v-if="$vuetify.breakpoint.mdAndUp">
      <div class="text-h4 font-weight-regular mb-2">
        Configure seus dados bancários

        <v-icon v-if="confirmed" color="success" large>mdi-check</v-icon>
      </div>

      <div class="text-body-1 mb-6">
        Nós usamos a Abmex como meio de pagamento
      </div>
    </section>

    <section
      v-if="!bankData"
      class="d-flex flex-column flex-md-row align-center"
    >
      <v-btn
        class="text-none rounded-lg mr-md-4 mb-4 mb-md-0"
        color="primary"
        :width="$vuetify.breakpoint.mdAndUp ? '20rem' : '100%'"
        depressed
        @click="addBankAccount()"
      >
        Configurar seus dados bancários
      </v-btn>

      <div class="text-bttn" @click="skip()">
        <span>Pular</span>
      </div>
    </section>

    <section
      v-if="bankData && !confirmed"
      class="d-flex flex-column flex-md-row align-center"
    >
      <v-btn
        class="text-none rounded-lg mr-4"
        color="primary"
        :width="$vuetify.breakpoint.mdAndUp ? '20rem' : '100%'"
        :loading="loading"
        depressed
        @click="submit()"
      >
        Confirmar
      </v-btn>
    </section>

    <section
      v-else-if="confirmed"
      class="d-flex justify-center justify-md-start"
    >
      <div class="text-bttn" @click="nextStep()">
        <span>Próximo passo</span>
        <v-icon color="primary" right>mdi-arrow-right</v-icon>
      </div>
    </section>

    <AddBankAccount ref="addBankAccount" @submit="handleBankAccount($event)" />
  </div>
</template>

<script>
import { request } from "@/services";
import AddBankAccount from "./partials/addBankAccount/AddBankAccount";

export default {
  name: "BankData",

  data() {
    return {
      loading: false,
      confirmed: false,
      bankData: null,
    };
  },

  components: {
    AddBankAccount,
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          method: "uploadUserBank",
          banco: this.bankData.bank.value,
          agencia: this.bankData.agency,
          conta: this.bankData.account,
          digito: this.bankData.digit,
        };

        await request(payload).then(() => {
          this.confirmed = true;
        });
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    nextStep() {
      this.$emit("submit", "bankData");
    },

    addBankAccount() {
      this.$refs.addBankAccount.open();
    },

    handleBankAccount(event) {
      this.bankData = event;

      const payload = {
        type: "bankData",
        data: event,
      };

      this.$emit("update", payload);
    },

    skip() {
      this.$emit("skip", "bankData");
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
